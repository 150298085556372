<template>
  <div class="row vh-100 m-0">
    <div class="d-flex align-items-center justify-content-center">
      <div>
        <h4 class="text-secondary text-center">Selecione uma Unidade:</h4>
        <loading-data v-if="isLoading" />
        <div v-else>
          <div class="" v-for="company in companies" :key="company.CODUNIDADE">
            <router-link
              :to="`companies/${company.CODUNIDADE}`"
              class="btn btn-secondary btn-lg mb-3 w-100"
              :title="`Selecionar ${company.NOMEFANTASIA}`"
            >
              {{ company.NOMEFANTASIA }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'

export default {
  name: 'Home',
  data() {
    return {
      isLoading: true,
      companies: [],
    }
  },
  components: {
    LoadingData,
  },
  methods: {
    async getCompanies() {
      try {
        this.companies = await this.$store.dispatch('getCompanies')
        this.isLoading = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('setPageTitle', ``)
    this.getCompanies()
  },
}
</script>
